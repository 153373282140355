import React, { useEffect, useState } from 'react';
import "../css/form.css"
import { useHistory } from "react-router";
import { FaRegPaperPlane } from "react-icons/fa";
import newbg from "../images/newbg.png";
import newbg1 from "../images/newbg1.png";
import axios from 'axios';
import Swal from 'sweetalert2'

const Form = (props) => {
    const data = props.location.state
    const history = useHistory();

    useEffect(() => {
        if (data === 'enterprise') {
            setFormState({ type: data })
        }
        else if (data === 'collaborator') {
            setFormState({ type: data })
        }
        else {
            setFormState({ email: data, type: 'enterprise' })
        }
    }, [])
    const [formState, setFormState] = useState(
        {
            type: "enterprise",
            company: "",
            fname: "",
            lname: "",
            email: "",
            phone: "",
            msg: ""

        })
    const _handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value })
    }
    const _submit = () => {
        if (formState.company === undefined && formState.fname === undefined || formState.email === undefined || formState.phone || formState.msg === undefined) {
            axios.post('https://signblock.lynksign.com/contactus', formState)
                .then(response => {
                    var message = response.data.message
                    if( message === 'success'){
                        setTimeout(()=>{
                            history.push('/home')
                        },2000)
                    }
                    Swal.fire({
                        position: 'bottom',
                        icon: 'info',
                        title: `${message}`,
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch(error => {
                    console.log("error", error);
                })
        }
    }

    return (
        <>
            <div className='formElem'>
                <img src={newbg} alt='' className="bgimg1" />
                <img src={newbg1} alt='' className="bgimg2" />
                <div className='innerFormElem'>
                    <div className='formRow'>
                        <input className='myInp' name='company' value={formState.company} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter Company Name' />
                    </div>
                    <div className='formRow'>
                        <input className='myInp' name="fname" value={formState.fname} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter Your First Name' />
                    </div>
                    <div className='formRow'>
                        <input className='myInp' name="lname" value={formState.lname} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter Your Last Name' />
                    </div>
                    <div className='formRow'>
                        <input className='myInp' name="email" value={formState.email} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter Your Email Id' />
                    </div>
                    <div className='formRow'>
                        <input className='myInp' name='phone' value={formState.phone} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter Your Phone Number' />
                    </div>
                    <div className='formRow flexCol'>
                        <span>Message</span>
                        <textarea name='msg' value={formState.msg} onChange={(e) => _handleChange(e)} className='textArea' />
                    </div>
                    <div className='formRow flexCol'>
                        <span className='requiredText'>To be a participating Node, you need to have a server with the below minimum requirements-:</span><br />
                        <span className='requiredText'>OS - Ubuntu (16.04)</span>
                        <span className='requiredText'>RAM - 4 GB</span>
                        <span className='requiredText'>ROM - 1 TB</span>
                    </div>
                    <div className='formRow'>
                        <button onClick={_submit} className='subBtn'><FaRegPaperPlane className="plane" /></button>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Form;