import { React, Switch, Route } from 'react-router-dom';
import Home from "./Components/Home";
import Navbar from './Components/Navbar';
import Signature from './Components/Signature';
import ScrollToTop from './Components/ScrollToTop';
import AboutUs from './Components/AboutUs';
import Advisory from './Components/AdvisoryBoard';
import Form from './Components/Form';
import Footer from './Components/Footer';
import {Helmet} from "react-helmet";
import Test from './Components/Test';

function App() {
  return (
    <>
      <Navbar/>
      <ScrollToTop/>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lynksign</title>
        <meta name="description" content="Welcome to the future of signing and sharing files, secured by blockchain, powered by everyone." />
        <link rel="icon" type="image/png" href="lynkit.ico" sizes="32x32" />

      </Helmet>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home}/>
        <Route exact path="/about-us" component={AboutUs}/>
        <Route exact path="/advisory" component={Advisory}/>
        <Route exact path="/Signature" component={Signature}/>
        <Route exact path="/home/form" component={Form}/>
        <Route exact path="/home/:viewToScroll" component={Home} />
      </Switch>   

       
    </>
  );
}

export default App;
