import React from 'react'
import "../css/about.css"
import flipkart from "../images/flipkart.jpg";
import samsung from "../images/samsung.jpg";
import konoike from "../images/konoike.jpg";
import dixon from "../images/dixon.jpg";
import delivery from "../images/delivery.jpg";
import mahindra from "../images/mahindra.png";
import dadri from "../images/dadri.jpg";
import landmark from "../images/one.jpg";
import bharat from "../images/two.jpg";
import fuel from "../images/fuel.png";
import blue from "../images/seven.jpg";
import aditya from "../images/four.jpg";
import sleep from "../images/six.jpg";
import ADB from "../images/ADB.png";
import lg from "../images/nine.jpg";
import carat from "../images/carat.png";
import sch from "../images/sch.png";
import teas from "../images/teas.jpg";
import cma from "../images/cma.jpg";

const Clients = () => {
    const image_array = [
        {
            image: samsung,
            id: ""
        },

        {
            image: konoike,
            id: ""
        },

        {
            image: bharat,
            id: "bharat"
        },

        {
            image: dixon,
            id: ""
        },

        {
            image: sleep,
            id: ""
        },

        {
            image: blue,
            id: ""
        },

        {
            image: delivery,
            id: ""
        },
        {
            image: mahindra,
            id: ""
        },
        {
            image: ADB,
            id: ""
        },
    ]
    const image_array_2 = [
        {
            image: landmark,
            id: ""
        },

        {
            image: flipkart,
            id: "cma"
        },
        {
            image: fuel,
            id: ""
        },
        {
            image: aditya,
            id: "aditya"
        },
        {
            image: lg,
            id: "lg"
        },
        {
            image: cma,
            id: "dadri"
        },
        {
            image: sch,
            id: "sch"
        },
        {
            image: teas,
            id: "teas"
        },
        {
            image: carat,
            id: ""
        }

    ]
    return (
        <>
            <div className="customer_div">
                <h1>Lynkit's clients</h1>
                <div className="customers_div_1">
                    {image_array.map((value) => (
                        <div><img className="customer_images" id={value.id} src={value.image} /></div>
                    )
                    )}
                </div>
                <div className="customers_div_2">
                    {image_array_2.map((value) => (
                        <div><img className="customer_images" id={value.id} src={value.image} /></div>
                    ))}
                </div>
                {/* <div className="customers_div_3">
                        {image_array_3.map((value) => (
                            <div><img className="customer_images"  id={value.id} src={value.image} /></div>
                        ))}
                    </div> */}
                <div id="more_3500"><h1>+ 3,500 more</h1></div>
            </div>

            <div className="customer_div1">
                <h1>Lynkit's clients</h1>
                <div className="customers_div_1">
                    <div><img className="customer_images" src={samsung} alt="none" /></div>
                    <div><img className="customer_images" src={konoike} alt="none" /></div>
                    <div><img className="customer_images" id="cma" src={flipkart} alt="none" /></div>
                    <div><img className="customer_images" id="dadri" src={cma} alt="none" /></div>
                </div>
                <div className="customers_div_2">
                    <div><img className="customer_images" src={landmark} alt="none" /></div>
                    <div><img className="customer_images" id="bharat" src={bharat} alt="none" /></div>
                    <div><img className="customer_images" src={fuel} alt="none" /></div>
                </div>
                <div className="customers_div_3">
                    <div><img className="customer_images" src={sleep} alt="none" /></div>
                    <div><img className="customer_images" src={blue} alt="none" /></div>
                    <div><img className="customer_images" src={delivery} alt="none" /></div>
                    <div><img className="customer_images" id="aditya" src={aditya} alt="none" /></div>
                </div>
                <div className="customers_div_4">
                    <div><img className="customer_images" src={dixon} alt="none" /></div>
                    <div><img className="customer_images" id="lg" src={lg} alt="none" /></div>
                    <div><img className="customer_images" id="ADB" src={ADB} alt="none" /></div>
                </div>
                <div className="customers_div_5">
                    <div><img className="customer_images" id="carat" src={carat} alt="none" /></div>
                    <div><img className="customer_images" src={mahindra} alt="none" /></div>
                    <div><img className="customer_images" id='sch' src={sch} alt="none" /></div>
                    <div><img className="customer_images" id="teas" src={teas} alt="none" /></div>

                </div>
                <div id="more_3500"><h1>+ 3,500 more</h1></div>
            </div>
        </>

    )
}
export default Clients;