import React, { useState } from "react";
import "../css/navbar.css";
import logo from "../images/logo1.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
const Navbar = () => {

    const [navbar, setNavbar] = useState(false)
    const [icon, setIcon] = useState(GiHamburgerMenu)
    const [icon_id, set_icn_id] = useState(false)
    const [cross_cls, set_cross_cls] = useState(false)

    const toggle_navbar = () => {
        setNavbar(!navbar)
        setIcon(ImCross)
        set_icn_id(!icon_id)
        set_cross_cls(!cross_cls)
    }

    return (
        <>
            <div className="navbar_div_outer">
                <div className="navbar_inner_1">
                    <a href="/home">
                        <img id="logo" src={logo} alt="none" />
                    </a>
                </div>
                <div className="navbar_inner_2">
                    <div className="inner_2_1">
                        <a href="/about-us">About us</a>
                        <a href="/advisory">Advisory board</a>
                        <GiHamburgerMenu onClick={toggle_navbar} id={icon_id ? "no_burger" : "burger"} className={icon} />
                        <ImCross onClick={toggle_navbar} className={cross_cls ? "crosss" : "hide_crosss"} />
                    </div>
                </div>
            </div>
            <div className={navbar ? "slide_navbar" : "hide_slide_nav"}>
                <a href="/about-us">About us</a>
                <a href="/advisory">Advisory board</a>
            </div>
        </>
    );
};

export default Navbar;
