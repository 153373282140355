import React, { useEffect, useRef } from "react";
import "../css/home.css"
import { useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { useHistory } from "react-router";
import axios from 'axios';
import otp_img from "../images/otp_image.png";
import bg from "../images/bg.jpg";
import folder from "../images/folder.png";
import lock from "../images/lock.png";
import pen from "../images/pen.png";
import three_folder from "../images/three_folder.png";
import equal from "../images/equal.png";
import not_equal from "../images/not_equal.png";
import verify from "../images/verify.png";
import projection from "../images/projection.png";
import concept from "../images/concept.png";
import { BsFillXSquareFill } from "react-icons/bs";
import { BsQuestionLg } from "react-icons/bs";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { FaLongArrowAltRight } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { GoMailRead } from "react-icons/go";
import ScrollToTop from "react-scroll-to-top";
import moment from 'moment';
import { MdOutlineRemoveCircle } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import { ThreeDots } from 'react-loader-spinner'
import { Link } from "react-router-dom";
import { FiX } from "react-icons/fi";
import Clients from "./Clients";
import Swal from 'sweetalert2'
import newbg from "../images/newbg.png";
import newbg1 from "../images/newbg1.png";
import btn1 from "../images/btn1.png";
import btn2 from "../images/btn2.png";
import blockchain from "../images/blockchain.png";
import lockw from "../images/lockw.png";
import verifyy from "../images/verifyy.png";

import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import Footer from "./Footer";
import { useParams } from "react-router";


const Home = () => {
    const partners_ref = useRef()
    const { viewToScroll } = useParams();

    useEffect(() => {
        if (viewToScroll === 'customers') {
            window.scrollTo(0, partners_ref.current.offsetTop - 11)
            // partners_ref.current.scrollIntoView();
        }

    });


    const history = useHistory();
    const form_data = {
        name_: "", email: "", place: ""
    }
    const [myFile, setMyFile] = useState()
    const verifying_object = { verify_data: "" }
    const [verify_state, set_verify_state] = useState(verifying_object)
    const { verify_data } = verify_state
    // const txn_data = { trxnId: "" }
    const [txn_state, set_txn_state] = useState({ trxnId: "" })
    // const { trxnId } = txn_state
    const otp_object = { otp: "" }
    const [otp_state, set_otp_state] = useState(otp_object)
    const { otp } = otp_state
    const location_object = { myLocation: "" }
    const [fetchlocation, setFetchLocation] = useState(location_object)
    const { myLocation } = fetchlocation
    const [total_files, set_total_files] = useState('')
    const { file_choosen } = total_files
    const [Form_data, set_form_data] = useState(form_data)
    const { name_, email, place } = Form_data
    const [secure_div, set_secure_div] = useState("")
    const [secure_div_1, set_secure_div_1] = useState("")
    const [secure_div_mobile, set_secure_div_mob] = useState("")
    const [secure_div_1_mobile, set_secure_div_1_mob] = useState("")
    const [verifying_div, set_verifying_div] = useState("")
    const [verifying_div_1, set_verifying_div_1] = useState("")
    const [verify_mobile_1, set_hide_verfiy_mob_1] = useState("")
    const [verifying_mob, set_verifying_mob] = useState("")
    const [verified_yes_mob, set_verified_yes_mob] = useState("")
    const [verified_no_mob, set_verified_no_mob] = useState("")
    const [verified_yes, set_verified_yes] = useState("")
    const [verified_no, set_verified_no] = useState("")
    const [modal, setModal] = useState(false)
    const [otp_screen, set_otp_screen] = useState(false)
    const [fileData, Set_fileData] = useState("")
    const [name_otp, set_name_otp] = useState()
    const [email_otp, set_email_otp] = useState()
    const [serverTime, setServerTime] = useState()
    const [verified_name, set_verified_name] = useState()
    const [verified_email, set_verified_email] = useState()
    const [verified_place, set_verified_place] = useState()
    const [fileType, set_file_type] = useState()
    const [signTime, setSignTime] = useState()
    const [fileName, setmyfilename] = useState()
    const [storeTime, setStoreTime] = useState("")
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    const [status, setStatus] = useState(null);
    const [places, setPlace] = useState("")
    const [fetched_location, setfetchedltn] = useState("hide_fetched_location")
    const [diabable, setDisable] = useState(false)
    const [remove_state, set_remove_state] = useState("hide_remove")
    const [loader, setLoader] = useState()
    const [currrPage, setCurrPage] = useState()
    const [totalPage, setTotalPage] = useState()
    const array = []
    const [pdfData, setPdfData] = useState([])
    const [image, setImage] = useState([])
    const [activeTab, setActiveTab] = useState('')
    const [intervalId, setIntervalId] = useState(0);
    const [passType, setPassType] = useState('password')
    const [uploadLoader, setUploadLoader] = useState(false)
    const [verifyStatus, setVerifyStatus] = useState(false)
    const [file_Typee, set_File_Type] = useState('')

    const setPassTypeFun = () => {
        if (passType === 'password') {
            setPassType('number')
        }
        else {
            setPassType('password')
        }
    }
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        })

        if (currrPage < totalPage) {
            setImage(pdfData)
        }
        else {
            setImage(pdfData)
        }
    }, [lat, lng, places, place, image, currrPage, totalPage, pdfData])

    const getLocation = async () => {
        if (!navigator.geolocation) {
            setStatus('Geolocation is not supported by your browser');
        }
        else {
            setStatus('Locating...');
            navigator.geolocation.getCurrentPosition((position) => {
                setStatus(null);
                if (lat !== undefined || lng !== undefined) {
                    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAMbjwVD8stwnb3sKYaxCOefn6ranbU7bE`)

                        .then(response => {
                            // console.log("aa",response.data.results[0].formatted_address);
                            const city = response.data.results[5].address_components[2].short_name
                            const postal = response.data.results[5].address_components[0].short_name
                            const fulladdress = response.data.results[0].formatted_address.slice(0, -21)
                            setPlace(fulladdress)
                            Form_data.place = ''
                            set_remove_state("remove")
                            setDisable(true)
                            setfetchedltn("fetched_location")
                            localStorage.removeItem("place")

                        })
                        .catch(error => {
                            console.log("error", error)
                        })
                }
                else {
                }

            }, () => {
                setStatus('Unable to retrieve your location');
                Swal.fire({
                    title: 'Unable to retrieve your location. Please enter manually.',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                })
            });
        }
    }
    const remove_fun = () => {
        setPlace("")
        setDisable(false)
        set_remove_state("hide_remove")
        setfetchedltn("hide_fetched_location")
    }
    const readFileData = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);

            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    };

    const handlePdfchange = async (e) => {
        const file = e.target.files[0]
        const fileLimit = file.size

        if (fileLimit < 10000000) {
            setMyFile(e.target.files[0])
            const selectedFile = file.name
            const file_type = file.type
            set_file_type(file_type)
            window.localStorage.clear();

            if (file_type === "application/pdf") {
                setUploadLoader(true)
                const data = await readFileData(file);
                const pdf = await pdfjsLib.getDocument(data).promise;
                const canvas = document.createElement("canvas");
                let tempArr = []
                for (let i = 0; i < pdf.numPages; i++) {
                    const page = await pdf.getPage(i + 1);
                    const viewport = page.getViewport({ scale: 10 });
                    const context = canvas.getContext("2d");
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    await page.render({ canvasContext: context, viewport: viewport }).promise;
                    //   images.append(canvas.toDataURL());
                    const res = canvas.toDataURL('image/jpeg')

                    tempArr.push(res)
                    setPdfData({ ...pdfData, tempArr })
                    setImage({ ...tempArr })
                    setUploadLoader(false)

                    set_total_files({ name: file.name })
                    setmyfilename(selectedFile)
                    set_secure_div(!secure_div)
                    set_secure_div_1(!secure_div_1)


                    // return res;
                }
                canvas.remove();
                // return images;
            }
            else if (file_type === "image/png" || file_type === "image/jpeg") {
                setUploadLoader(true)
                const base64 = await convertBase64(file);
                array.push(base64)
                setPdfData({ ...pdfData, array })
                setImage(pdfData)
                setUploadLoader(false)
                set_total_files({ name: file.name })
                setmyfilename(selectedFile)
                set_file_type(file_type)
                set_secure_div(!secure_div)
                set_secure_div_1(!secure_div_1)

            }
            else {
                Swal.fire({
                    title: 'Choose .pdf , .png or .jpeg file only.',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                })
            }
        }
        else {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'Maximum file size exceeded 10 MB .',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }
    // const handlePdfchange = async (e) => {
    //     const file = e.target.files[0]
    //     const fileLimit = file.size
    //     if (fileLimit < 10000000) {
    //         setMyFile(e.target.files[0])
    //         const selectedFile = file.name
    //         const file_type = file.type
    //         set_file_type(file_type)
    //         window.localStorage.clear();

    //         if (file_type === "application/pdf") {
    //             setUploadLoader(true)
    //             const pdfResponse = await get_pdf_thumbnail(file)
    //             setPdfData({ ...pdfData, pdfResponse })
    //             setImage(...pdfData)
    //             // setUploadLoader(false)
    //             set_total_files({ name: file.name })
    //             setmyfilename(selectedFile)
    //             set_secure_div(!secure_div)
    //             set_secure_div_1(!secure_div_1)

    //         }
    //         else if (file_type === "image/png" || file_type === "image/jpeg") {
    //             setUploadLoader(true)
    //             const base64 = await convertBase64(file);
    //             array.push(base64)
    //             setPdfData({ ...pdfData, array })
    //             setImage(pdfData)
    //             set_total_files({ name: file.name })
    //             setmyfilename(selectedFile)
    //             set_file_type(file_type)
    //             set_secure_div(!secure_div)
    //             set_secure_div_1(!secure_div_1)

    //         }
    //         else {
    //             Swal.fire({
    //                 title: 'Choose .pdf , .png or .jpeg file only.',
    //                 showClass: {
    //                     popup: 'animate__animated animate__fadeInDown'
    //                 },
    //                 hideClass: {
    //                     popup: 'animate__animated animate__fadeOutUp'
    //                 }
    //             })
    //         }
    //     }
    //     else {
    //         Swal.fire({
    //             position: 'bottom',
    //             icon: 'info',
    //             title: 'Maximum file size exceeded 10 MB .',
    //             showConfirmButton: false,
    //             timer: 1500
    //         })
    //     }
    // }
    const handlePdfchange_mobile = async (e) => {
        const file = e.target.files[0]
        const newFileLimit = file.size
        if (newFileLimit < 10000000) {
            const selectedFile = file.name
            const file_type = file.type
            set_file_type(file_type)
            window.localStorage.clear();

            if (file_type == "application/pdf") {
                setUploadLoader(true)
                const data = await readFileData(file);
                const pdf = await pdfjsLib.getDocument(data).promise;
                const canvas = document.createElement("canvas");
                let tempArr = []
                for (let i = 0; i < pdf.numPages; i++) {
                    const page = await pdf.getPage(i + 1);
                    const viewport = page.getViewport({ scale: 10 });
                    const context = canvas.getContext("2d");
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    await page.render({ canvasContext: context, viewport: viewport }).promise;
                    //   images.append(canvas.toDataURL());
                    const res = canvas.toDataURL('image/jpeg')

                    tempArr.push(res)
                    setPdfData({ ...pdfData, tempArr })
                    setImage({ ...tempArr })
                    setUploadLoader(false)
                    set_total_files({ name: file.name })
                    setmyfilename(selectedFile)
                    set_secure_div_mob(!secure_div_mobile)
                    set_secure_div_1_mob(!secure_div_1_mobile)
                }
                canvas.remove();
            }
            else if (file_type == "image/png" || file_type == "image/jpeg") {
                setUploadLoader(true)
                const base64 = await convertBase64(file);
                array.push(base64)
                setPdfData({ ...pdfData, array })

                setImage(pdfData)
                set_total_files({ name: file.name })
                set_file_type(file_type)
                setmyfilename(selectedFile)
                set_secure_div_mob(!secure_div_mobile)
                set_secure_div_1_mob(!secure_div_1_mobile)
            }
            else {
                Swal.fire({
                    title: 'Choose .pdf , .png or .jpeg file only.',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                })
            }
        }
        else {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'Maximum file size exceeded 10 MB .',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }
    function get_pdf_thumbnail(file) {
        return new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = function () {
                pdfjsLib.getDocument(fileReader.result).promise.then(function (doc) {
                    var pages = [];
                    const currPage = pages.length + 1
                    setCurrPage(currPage)
                    const totalPages = doc.numPages
                    setTotalPage(totalPages)

                    while (pages.length < doc.numPages) pages.push(pages.length + 1);
                    return Promise.all(pages.map((num) => {
                        return (
                            doc.getPage(num).then(makeThumb)
                                .then(async function (canvas) {
                                    setUploadLoader(false)
                                    const res = await canvas.toDataURL('image/jpeg')
                                    return res;
                                })
                                .catch((err) => {
                                    console.log("Error", err)
                                    // resolve()
                                    return;
                                })
                        )
                    })).then((rrrr) => {
                        return resolve(rrrr);
                    })

                }, function (error) {
                    console.log(error);
                    resolve()
                });
            };

            fileReader.readAsDataURL(file);
        })
    }
    function makeThumb(page) {
        var vp = page.getViewport({ scale: 10 });
        var canvas = document.createElement("canvas");
        canvas.width = 596;
        canvas.height = 842;
        var scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
        return page.render({ canvasContext: canvas.getContext("2d"), viewport: { scale: scale } }).promise.then(function () {
            return canvas
        })
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result)
                setUploadLoader(false)
            }

            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }
    const getUserSign = (e) => {
        e.preventDefault();
        const otp_data = { otp, email_otp }
        document.body.classList.remove('active_modal')
        setModal(!modal)
        axios.post('https://signblock.lynksign.com/verifyOTP', otp_data)
            .then(response => {
                // console.log(response);
                const response_status = response.data.message
                if (response_status == "Success") {
                    history.push({
                        pathname: "/Signature",
                        state: { myFile, image, name_, email, place, fileType, storeTime, total_files, places }
                    })
                }
                else if (response_status == "Failure") {
                    Swal.fire({
                        position: 'bottom',
                        icon: 'info',
                        title: "Please enter correct OTP.",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                else if (response_status == 'Please provide valid email') {
                    Swal.fire({
                        position: 'bottom',
                        icon: 'info',
                        title: "Please provide valid email.",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }

            })
            .catch(error => {
                console.log("error", error)
            })
    }
    const handleChange = (e) => {
        if(e.target.name === 'place'){
            setPlace('')
        }
        set_form_data({ ...Form_data, [e.target.name]: e.target.value })
        set_name_otp(name_)
        set_email_otp(email)

    }
    const otp_fun = (e) => {
        set_otp_state({ ...otp_state, [e.target.name]: e.target.value })

    }
    const toggle_verify_div = (e) => {
        e.preventDefault();
        set_verifying_div(!verifying_div)
        set_secure_div(!secure_div)
    }
    const toggle_verify_div_mob = (e) => {
        e.preventDefault();
        set_verifying_mob(!verifying_mob)
        set_hide_verfiy_mob_1(!verify_mobile_1)

    }
    const hide_verify = (e) => {
        e.preventDefault();
        if (txn_state.trxnId.length !== 0 && verify_data == undefined) {
            set_verifying_div(!verifying_div)
            set_verifying_div_1(!verifying_div_1)

        }
        else if (txn_state.trxnId.length == 0 && verify_data == undefined) {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'Please Enter Txn Id.',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else if (txn_state.trxnId.length !== 0 && verify_data.length == 0) {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'Please Choose your File.',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'Please fill Txn ID and choose your File.',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }
    const switch_secure_div = (e) => {
        window.location.reload()
        e.preventDefault();
        set_secure_div(!secure_div)
        set_secure_div_1(!secure_div_1)

    }
    const switch_secure_div_mob = (e) => {
        window.location.reload()
        e.preventDefault();
        set_secure_div_mob(!secure_div_mobile)
        set_secure_div_1_mob(!secure_div_1_mobile)
    }
    const move_back = (e) => {
        window.location.reload()
        e.preventDefault();
        set_secure_div(!secure_div)
        set_verified_yes(!verified_yes)
        set_secure_div_1(secure_div_1)


        set_txn_state({ txn_state: "" })

    }
    const move_back_1 = (e) => {
        e.preventDefault();
        window.location.reload()
        set_secure_div(!secure_div)
        set_verified_no(!verified_no)
        set_secure_div_1(secure_div_1)
        set_txn_state("")
    }
    const show_otp_pop_up = (e) => {
        e.preventDefault();
        if (name_.length == 0 || email.length == 0 || (place.length === 0 && places.length === 0)) {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'All Fields Mandatory.',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            const final_verifying_otp = { name_otp, email_otp, fileName }
            set_otp_screen(!otp_screen)
            axios.post('https://signblock.lynksign.com/sendOTP', final_verifying_otp)
                .then(response => {
                    // console.log("response", response)
                })
                .catch(error => {
                    console.log("error", error)
                })
        }
    }
    if (otp_screen) {
        document.body.classList.add('active_modal')
    } else {
        document.body.classList.remove('active_modal')
    }
    const store_txn_id = (e) => {
        set_txn_state({ ...txn_state, [e.target.name]: e.target.value })
    }
    const if_verified = async (e, remove_last_time) => {
        e.preventDefault();
        setVerifyStatus(true)
        if (txn_state.trxnId.length !== 0 && verify_data == undefined) {
            let formData = new FormData();
            axios.defaults.baseURL = "https://signblock.lynksign.com";
            formData.append("filename", fileData);
            formData.append("trxnId", txn_state.trxnId);
            return axios({
                method: "post",
                url: "/test/transactions/h7899",
                data: formData,
            })
                .then(response => {
                    const response_message = response.data.message
                    if (response_message === 'Document not verified on Blockchain') {
                        set_verified_no(!verified_no)
                        set_verifying_div_1(!verifying_div_1)
                        setLoader(loader)
                        setVerifyStatus(false)
                    }
                    else {
                        // console.log('verfication response', response);
                        const response_data1 = response.data.data.name
                        const response_data2 = response.data.data.email
                        const response_data3 = response.data.data.place
                        const response_data4 = response.data.data.secured_at
                        const format = moment(response_data4).format("YYYY/MM/DD hh:mm:ss a")
                        // const remove_extra = response_data4.slice(0, -5);
                        // const remove_t = remove_extra.replace("T", ", ")
                        // const format = moment(remove_t).format("DD MMM YYYY hh:mm:ss a")
                        setServerTime(remove_last_time)
                        set_verified_yes(!verified_yes)
                        set_verifying_div_1(!verifying_div_1)
                        set_verified_name(response_data1)
                        set_verified_email(response_data2)
                        set_verified_place(response_data3)

                        setSignTime(format)

                        setLoader(loader)
                        setVerifyStatus(false)
                    }
                })
                .catch(error => {
                    Swal.fire({
                        position: 'bottom',
                        icon: 'info',
                        title: "Unable to process this action",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setVerifyStatus(false)
                })
        }
        else if (txn_state.trxnId.length == 0 && verify_data == undefined) {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'Please Enter Txn Id.',
                showConfirmButton: false,
                timer: 1500
            })
            setVerifyStatus(false)
        }
        else if (txn_state.trxnId.length !== 0 && verify_data.length == 0) {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'Please Choose your File.',
                showConfirmButton: false,
                timer: 1500
            })
            setVerifyStatus(false)
        }
        else {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'Please fill Txn ID and choose your File.',
                showConfirmButton: false,
                timer: 1500
            })
            setVerifyStatus(false)
        }
    }
    const if_verified_mob = async (e, remove_last_time) => {
        e.preventDefault();
        setVerifyStatus(true)
        if (txn_state.trxnId.length !== 0 && verify_data == undefined) {
            let formData = new FormData();
            axios.defaults.baseURL = "https://signblock.lynksign.com";
            formData.append("filename", fileData);
            formData.append("trxnId", txn_state.trxnId);
            let response = await axios({
                method: "post",
                url: "/test/transactions/h7899",
                data: formData,
            })
                .then(response => {
                    const response_message = response.data.message
                    if (response_message === 'Document not verified on Blockchain') {
                        set_verified_no_mob(!verified_no_mob)
                        set_verifying_mob(!verifying_mob)
                        setVerifyStatus(false)
                    }
                    else {
                        const response_data1 = response.data.data.name
                        const response_data2 = response.data.data.email
                        const response_data3 = response.data.data.place
                        const response_data4 = response.data.data.secured_at
                        const format = moment(response_data4).format("YYYY/MM/DD hh:mm:ss a")
                        // const remove_extra = response_data4.slice(0, -5);
                        // const remove_t = remove_extra.replace("T", ", ")
                        // const format = moment(remove_extra).format("DD MMM YYYY hh:mm:ss a")
                        setServerTime(remove_last_time)
                        set_verified_yes_mob(!verified_yes_mob)
                        set_verifying_mob(!verifying_mob)
                        set_verified_name(response_data1)
                        set_verified_email(response_data2)
                        set_verified_place(response_data3)

                        setSignTime(format)
                        setVerifyStatus(false)

                    }
                })
                .catch(error => {
                    Swal.fire({
                        position: 'bottom',
                        icon: 'info',
                        title: "Unable to process this action",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setVerifyStatus(false)
                })
        }

        else if (txn_state.trxnId.length == 0 && verify_data == undefined) {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'Please Enter Txn Id.',
                showConfirmButton: false,
                timer: 1500
            })
            setVerifyStatus(false)
        }
        else if (txn_state.trxnId.length !== 0 && verify_data.length == 0) {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'Please Choose your File.',
                showConfirmButton: false,
                timer: 1500
            })
            setVerifyStatus(false)
        }
        else {
            Swal.fire({
                position: 'bottom',
                icon: 'info',
                title: 'Please fill Txn ID and choose your File.',
                showConfirmButton: false,
                timer: 1500
            })
            setVerifyStatus(false)
        }
    }
    const save_verifying_file = async (e) => {
        e.preventDefault();
        window.localStorage.clear();
        const verifying_file = e.target.files[0]
        const my_file_type = verifying_file.type
        set_File_Type(my_file_type)

        if (my_file_type == "application/pdf") {
            set_verify_state({ name: verifying_file.name })
            // const base64_uploaded_file = await convertBase64OfVerifyingFile(verifying_file);
            Set_fileData(e.target.files[0])
        }
        else if (my_file_type == "image/jpeg" || my_file_type == "image/jpg" || my_file_type == "image/png") {
            set_verify_state({ name: verifying_file.name })
            // const base64_uploaded_file = await convertBase64OfVerifyingFile(verifying_file);
            Set_fileData(e.target.files[0])
        }
        else {
            Swal.fire({
                title: 'Choose .pdf , .png or .jpeg file only.',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                }
            })
        }

    }
    const convertBase64OfVerifyingFile = (verifying_file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(verifying_file);

            fileReader.onload = () => {
                resolve(fileReader.result)
            }

            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }
    const getTime = () => {
        setActiveTab('first')
        axios.get('https://signblock.lynksign.com/serverTime')
            .then(response => {
                const api_time = response.data.data
                const remove_last_time = api_time.slice(0, -4);
                const modified_time = remove_last_time.replace("T", ", Time-")
                let updated_API_time = moment(api_time).valueOf() - 5 * 60 * 60 * 1000 - 30 * 60 * 1000;
                setServerTime(modified_time)

                const newIntervalId = setInterval(() => {
                    updated_API_time += 1000;
                    const final_time = moment(updated_API_time).format("DD MMM YYYY hh:mm:ss a")
                    setStoreTime(final_time)
                }, 1000);
                setIntervalId(newIntervalId);
            })
            .catch(error => {
                console.log("error", error)
            })
    }
    const mob_move_back = (e) => {
        e.preventDefault();
        set_verified_yes_mob(!verified_yes_mob)
        set_hide_verfiy_mob_1(!verify_mobile_1)
        window.location.reload()
    }
    const no_moving_back = (e) => {
        e.preventDefault();
        set_verified_no_mob(!verified_no_mob)
        set_hide_verfiy_mob_1(!verify_mobile_1)
        window.location.reload()
    }
    if (!loader) {
        document.body.classList.remove('active_modal')
    } else {
        document.body.classList.add('active_modal')
    }
    const spinner = () => {
        setLoader(!loader)
    }
    const close_otp_pop_up = () => {
        set_otp_screen(!otp_screen)
    }
    const openForm = (item) => {
        if (item === 'enterprise') {
            history.push({
                state: 'enterprise',
                pathname: '/home/form'
            })
        }
        else if (item === 'collaborator') {
            history.push({
                state: 'collaborator',
                pathname: '/home/form'
            })
        }

    }


    return (
        <>
            <ScrollToTop id="scrolling_to_top" smooth color="black" />

            <div className="home_div_outer">
                <img src={newbg} alt='' className="bgimg1" />
                <img src={newbg1} alt='' className="bgimg2" />
                <div className="newLynkElem">
                    <div className="newLynkRow">
                        <div className="newLynkHead">
                            <div className="bigElem1">
                                <span>S</span>
                            </div>
                            <div className="bigElem2">
                                <span className="headSpan1">imple &</span>
                                <span className="headSpan2">ecured;</span>
                            </div>
                        </div>
                    </div>
                    <div className="newLynkRow1">
                        <div className="newLynkHeadBtn">
                            <div className="nodebtn" onClick={() => openForm('enterprise')}>
                                <img className="btnImg" src={btn1} alt='' />
                                <span className="btnSpan">Convenience on the next <br />level, go enterprise.</span>
                            </div>
                            <div className="nodebtn" onClick={() => openForm('collaborator')}>
                                <img className="btnImg" src={btn2} alt='' />
                                <span className="btnSpan">Join as a collaborator,<br />be a participating node.</span>
                            </div>
                            <span className="btnNewSpan">Welcome to the future of signing and sharing files, secured by blockchain, powered by everyone.</span>
                        </div>
                    </div>
                </div>
                <div className="newLynkElem1">
                    <img className="blockchain" src={blockchain} alt='' />
                </div>


            </div>

            <div className="lynksign_work_div">
                <div className="lynksign_work_inner">
                    <h2>How does <span id="lynk_color">Lynksign</span>. Work?</h2>
                </div>
                <div className="working_steps">
                    <div className="one">
                        <div className="one_text">
                            <p>Upload any pdf or image and sign it with your details! The location and time will be auto fetched.</p>
                        </div>

                        <div className="one_dotted">
                            <div className="one_inner">
                                <img className="folder" src={folder} alt="none" />
                            </div>
                        </div><FaLongArrowAltRight id="arrow_one" />
                    </div>

                    <div className="two">

                        <div className="two_dotted">
                            <div className="two_inner">
                                <img className="lock" alt="none" src={lock} />
                            </div>
                        </div>

                        <div className="two_text">
                            <p>The document's footprint  is converted into a hashcode using blockchain cryptography. We never store the document - your data is secure.</p>
                        </div><FaLongArrowAltRight id="arrow_two" />
                    </div>

                    <div className="three">
                        <div className="three_text">
                            <p>Download the signed version and share with anyone and in anyway!</p>
                        </div>

                        <div className="three_dotted">
                            <div className="three_inner">
                                <img className="pen" src={pen} alt="none" />
                            </div>
                        </div><FaLongArrowAltRight id="arrow_three" />
                    </div>

                    <div className="four">
                        <div className="four_dotted">
                            <div className="four_inner">
                                <img className="three_folder" alt="none" src={three_folder} />
                            </div>
                        </div>

                        <div className="four_text">
                            <p>Anyone can come to lynksign, enter the unique txn id and upload the document to see if it was the same you signed!</p>
                        </div>
                    </div>

                    <div className="five">
                        <div className="five_text">
                            <p>In case the doc has been altered after you signed it, the platform will indicate it is not verified. In case it the same, the document will be authenticate via the blockchain and indicate it to the verifier!</p>
                        </div>

                        <div className="five_dotted">
                            <div className="five_inner">
                                <img className="equal" src={equal} alt="none" />
                                <img className="not_equal" src={not_equal} alt="none" />
                            </div>
                        </div><FaLongArrowAltRight id="arrow_four" />
                    </div>



                </div>

                <div className="working_steps_mobile_1">

                    <div className="one_mobile">
                        <div className="one_dotted_mobile">
                            <div className="one_inner_mobile">
                                <img className="folder_mobile" src={folder} alt="none" />
                            </div>
                            <FaLongArrowAltRight id="arrow_one_mobile" />
                        </div>

                        <div className="one_text_mobile">
                            <p>Upload any pdf or image and sign it with your details! The location and time will be auto fetched.</p>
                        </div>

                    </div>

                    <div className="two_mobile">

                        <div className="two_text_mobile">
                            <p>The document's footprint  is converted into a hashcode using blockchain cryptography. We never store the document - your data is secure.</p>
                            <FaLongArrowAltRight id="arrow_two_mobile" />
                        </div>

                        <div className="two_dotted_mobile">
                            <div className="two_inner_mobile">
                                <img className="lock_mobile" alt="none" src={lock} />
                            </div>
                        </div>


                    </div>

                </div>

                <div className="working_steps_mobile_2">

                    <div className="three_mobile">
                        <div className="three_dotted_mobile">
                            <div className="three_inner_mobile">
                                <img className="pen" src={pen} alt="none" />
                            </div><FaLongArrowAltRight id="arrow_three_mobile" />
                        </div>

                        <div className="three_text_mobile">
                            <p>Download the signed version and share with anyone and in anyway!</p>
                        </div>
                    </div>

                    <div className="four_mobile">

                        <div className="four_text_mobile">
                            <p>Anyone can come to lynksign, enter the unique txn id and upload the document to see if it was the same you signed!</p>
                        </div><FaLongArrowAltRight id="arrow_four_mobile" />

                        <div className="four_dotted_mobile">
                            <div className="four_inner_mobile">
                                <img className="lock_mobile" alt="none" src={three_folder} />
                            </div>
                        </div>


                    </div>

                    <div className="five_mobile">

                        <div className="five_dotted_mobile">
                            <div className="five_inner_mobile">
                                <img className="equal_mobile" src={equal} alt="none" />
                                <img className="not_equal_mobile" src={not_equal} alt="none" />
                            </div>
                        </div>

                        <div className="five_text_mobile">
                            <p>In case the doc has been altered after you signed it, the platform will indicate it is not verified. In case it the same, the document will be authenticate via the blockchain and indicate it to the verifier!</p>
                        </div>
                    </div>

                </div>


            </div>

            <div className="lynksign_for">

                <div className="lynksign_for_inner">
                    <h2><span id="lynk_clr">Who is</span> <span id="color_orange">Lynksign.</span> <span id="lynk_clr">For?</span> <span id="color_orange">Everyone.</span></h2>
                </div>

                <div className="lynksign_data">

                    <div className="curved_div_1">
                        <img src={verify} className="verify" alt="none" />

                        <p>Having your employer sign and verify your payslip, or your teacher sign your latest recommendation letter!</p>

                    </div>

                    <div className="curved_div_2">
                        <img src={projection} className="projection" alt="none" />
                        <p>Ensuring no one alters your next financial projections before you send it across!</p>
                    </div>

                    <div className="curved_div_3">
                        <img src={concept} className="concept" alt="none" />
                        <p>Claiming rights over your newest concept note and idea before sharing it with potential investors!</p>
                    </div>

                </div>

                <div className="lynksign_data_mobile">
                    <div className="curved_1_mobile">
                        <img src={verify} alt="none" />
                        <p>Having your employer sign and verify your payslip, or your teacher sign your latest recommendation letter!</p>
                    </div>

                    <div className="curved_2_mobile">
                        <img src={projection} alt="none" />
                        <p>Ensuring no one alters your next financial projections before you send it across!</p>
                    </div>

                    <div className="curved_3_mobile">
                        <img src={concept} alt="none" />
                        <p>Claiming rights over your newest concept note and idea before sharing it with potential investors!</p>
                    </div>
                </div>


            </div>

            <div className={secure_div ? "hide_secure_div" : " secure_verify_div"}>
                <div className="secure_verify_inner">
                    <div className="secure_left">
                        <div className="secure_inner">
                            <div className="lockw">
                                <div className="myLock">
                                    <img className="lockImg" src={lockw} alt='' />
                                </div>
                                <h2> I want to <span id="secure_color">Secure</span></h2>
                            </div>
                            <h4>No need to register, just sign,<br /> download and share.</h4>
                        </div>
                        <div className="upload_secure">
                            <h2 >Upload your pdf or jpeg.</h2>
                        </div>
                        <div className="choosse_div">
                            <button className="choose_div_btn">
                                <input type="file" name="file_choosen" value={file_choosen} id="upload-photo" onChange={handlePdfchange} onClick={getTime} />
                                <h2 id="overlap_text">Choose your File</h2>
                            </button>
                        </div>
                    </div>

                    <div className="verify_right">
                        <div className="verify_inner">
                            <div className="lockw">
                                <div className="myLock">
                                    <img className="lockImg" src={verifyy} alt='' />
                                </div>
                                <h2>I want to <span id="verify_color">Verify</span></h2>
                            </div>
                            <h4>No need to register, just sign,<br /> download and share.</h4>
                        </div>
                        <div className="verify_secure">
                            <h2>Upload your jpeg or pdf.</h2>
                        </div>
                        <div className="choose_div_1">
                            <button className="want_verify" onClick={toggle_verify_div}>Verify</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={secure_div_1 ? "show_secure_div" : " toggle_secure_div"}     >
                <div className="securing_main_div">
                    <div className="securing_left">

                        <div className="securing_inner">
                            <div className="lockw">
                                <div className="myLock">
                                    <img className="lockImg" src={lockw} alt='' />
                                </div>
                                <h2>I am <span id="securing">Securing</span></h2>
                            </div>
                            <h4>No need to register, just sign,<br /> download and share.</h4>
                        </div>

                        <div className="upload_securing">
                            <p id="main_file">{total_files.name}</p>
                            <button className="another_upload" onClick={switch_secure_div}>Upload Another</button>
                        </div>
                    </div>

                    <div className="securing_right">
                        <div className="securing_form">
                            <label id="locatione" onClick={getLocation}> Fetch Current Location <MdLocationPin id="location_icon" onClick={getLocation} /></label><br /><br />
                            <form >
                                <label id="focuss"> Name:</label> <input type="text" name="name_" value={name_} onChange={(e) => handleChange(e)} required /><br /><br /><br />
                                <label > Email: </label><input type="email" name="email" value={email} onChange={(e) => handleChange(e)} required /><br /><br /><br />
                                <label > Place: <span id={fetched_location}>{places}</span><MdOutlineRemoveCircle id={remove_state} onClick={remove_fun} /> </label> 
                                <input type="text" name="place" value={place} onChange={(e) => handleChange(e)} disabled={diabable} id="clear_me" required /><br /><br /><br />
                                <label> Time:</label><input id="server_time" disabled placeholder={storeTime} /> <br /><br />
                                <button type="submit" onClick={show_otp_pop_up} onTouchStart={(e) => handleChange(e)} onMouseEnter={(e) => handleChange(e)} className="sign">Sign</button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className={verifying_div ? "show_verifying_div" : "hide_verifying_div"}>
                <div className="inner_verifying">
                    <div className="inner_verifying_left">
                        <div className="text_img">
                            <h3>Is it real.....</h3>
                            <BsQuestionLg id="question" />
                        </div>
                    </div>

                    <div className="inner_verifying_right">
                        <div className="heading_verify">
                            <h2>I am <span id="verifying_text">Verfying</span></h2>
                            <h4>No need to register, just upload and authenticate.</h4>
                        </div>

                        <div className="form_verifying">
                            <form>
                                <label > Txn Id: <input name="trxnId" id="get_id" value={txn_state.trxnId} onChange={(e) => store_txn_id(e)} type="text" /></label><br /><br /><br />

                                <button className="choose_div_btn_1">
                                    <input type="file" name="verify_data" value={verify_data} onChange={save_verifying_file} id="upload-photo3" />
                                    <h2 id="overlap_text_1">Choose your File</h2>
                                </button>
                                <button onClick={hide_verify} className="submit_id">Submit</button>

                            </form>
                        </div>
                    </div>



                </div>
            </div>

            <div className={verifying_div_1 ? "show_verifying_div_1" : "hide_verifying_div_1"}>
                <div className="inner_verifying_1">
                    <div className="inner_verifying_left">
                        <div className="text_img">
                            <h3>Is it real.....</h3>
                            <BsQuestionLg id="question" />
                        </div>
                    </div>

                    <div className="inner_verifying_right">
                        <div className="heading_verify">
                            <h2>I am <span id="verifying_text">Verfiying</span></h2>
                            <h4>No need to register, just upload and authenticate.</h4>
                        </div>

                        <div className="form_verifying_1">
                            <form>
                                <p id="to_left" >Txn Id : <span id="special_text_1">{txn_state.trxnId}</span></p><br /><br /><br />
                                <p id="to_left1"> Your File :<span id="special_text_2"> {verify_state.name} </span> </p><br />

                                <span id="special_text_1_new">{txn_state.trxnId}</span><br /><br /><br />
                                <span id="special_text_2_new"> {verify_state.name} </span> <br />

                                <button className="submit_id_1" onClick={if_verified}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className={verified_yes ? "verified_yes" : "hide_verified_yes"}>
                <div className="verified_yes_inner">
                    <div className="verified_yes_left">
                        <div className="yes_1">
                            <h2>The document was signed with the following details-:</h2>
                        </div>
                        <div className="yes_2">
                            <div className="yes_2_inner">
                                <div className="boxes">
                                    <div className="box_0"></div>
                                    <div className="box_1">
                                        <p >Name : </p>
                                    </div>
                                    <div className="box_2">
                                        <span id="special_color1">{verified_name}</span>
                                    </div>
                                </div>
                                <div className="boxes">
                                    <div className="box_0"></div>
                                    <div className="box_1">
                                        <p>Email :  </p>
                                    </div>
                                    <div className="box_2">
                                        <span id="special_color2">{verified_email}</span>
                                    </div>
                                </div>
                                <div className="boxes">
                                    <div className="box_0"></div>
                                    <div className="box_1">
                                        <p>Place : </p>
                                    </div>
                                    <div className="box_2">
                                        <span id="special_color3">{verified_place}</span>
                                    </div>
                                </div>

                                <div className="boxes">
                                    <div className="box_0"></div>
                                    <div className="box_1">
                                        <p >Time :  </p>
                                    </div>
                                    <div className="box_2">
                                        <span id="special_color4">{signTime}</span>
                                    </div>
                                </div>
                            </div>
                            <p id="special_green">The document has been successfully authenticated on blockchain...</p>
                        </div>


                    </div>

                    <div className="verified_yes_right">
                        <div className="heading_verify_new">
                            <h2>I am <span id="white_text">Verfied...</span></h2>
                            <h4>No need to register, just upload and authenticate.</h4>
                        </div>
                        <div className="form_verifying_new">
                            <form>
                                <p id="trxnid">Txn Id : <span id="special_text_white">  {txn_state.trxnId} </span></p><br />
                                <p id="choose_file"> Your File :<span id="special_text_white1">{verify_state.name}</span> </p>

                                <span id="special_text_white_new">  {txn_state.trxnId} </span><br />
                                <span id="special_text_white1_new">{verify_state.name}</span>
                                <button className="submit_id_green" onClick={move_back} >Verify Another</button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className={verified_no ? 'not_verified' : 'hide_not_verified'}>
                <div className="not_verified_inner">
                    <div className="not_verified_left">
                        <div className="no_1">
                            <h2> The document’s authenticity could not be verified on blockchain.</h2>
                            <ImCross id="cross" />
                        </div>
                    </div>

                    <div className="not_verified_right">
                        <div className="heading_verify_new">
                            <h2> Not <span id="verifying_text_white">Verfied</span></h2>
                            <h4>No need to register, just upload and authenticate.</h4>
                        </div>
                        <div className="form_verifying_new">
                            <form>
                                <p id="trxnid_no" >Txn Id : <span id="special_text_white2">   {txn_state.trxnId}  </span></p><br />
                                <p id="choose_file_no"> Your File :<span id="special_text_white3"> {verify_state.name} </span> </p>

                                <span id="special_text_white2_new">   {txn_state.trxnId}  </span><br />
                                <span id="special_text_white3_new"> {verify_state.name} </span>
                                <button className="submit_id_red" onClick={move_back_1} >Verify Another</button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* ****************MOBILE SECURE******************* */}
            <div className={secure_div_mobile ? "hide_secure_main_mobile" : "secure_main_mobile"}>
                <div className="secure_mobile">
                    <div className="secure_mobile_inner">

                        <div className="secure_mobile_head">
                            <h2>I want to <span id="secure__mobile_color">Secure</span></h2>
                            <h4>No need to register, just sign,<br /> download and share.</h4>
                            <h2 id="upload_pdf" >Upload your pdf or jpeg.</h2>
                        </div>

                        <div className="choosse_div_mobile">
                            <button className="choose_div_btn_mobile">
                                <input type="file" name="file_choosen" value={file_choosen} id="upload-photo1" onChange={handlePdfchange_mobile} onClick={getTime} />
                                <h2 id="overlap_text_mobile">Choose your File</h2>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={secure_div_1_mobile ? "securing_file_div" : "hide_securing_file_div"}>
                <div className="securing_file_inner">
                    <div className="file_securing">
                        <p>Securing : <span id="file_name_mob">{total_files.name}</span></p>
                        <button className="another_upload_mob" onClick={switch_secure_div_mob}>Upload Another</button>
                    </div>
                    <div className="securing_file_daata">
                        <label id="locatione" onClick={getLocation}> Fetch Current Location <MdLocationPin id="location_icon_mob" onClick={getLocation} /></label>
                        <form>
                            <label id="focuss_mobile"> Name:</label> <input type="text" name="name_" value={name_} onChange={(e) => handleChange(e)} required /><br /><br /><br />
                            <label > Email: </label><input type="email" name="email" value={email} onChange={(e) => handleChange(e)} required /><br /><br /><br />
                            <label > Place: <span id={fetched_location}>{places}</span><MdOutlineRemoveCircle id={remove_state} onClick={remove_fun} /> </label> 
                            <input disabled={diabable} type="text" name="place" value={place} onChange={(e) => handleChange(e)} required /><br /><br /><br /><br /><br />
                            <label id="label_timee_mob"> Time:
                                <span id="server_time">
                                    {storeTime}
                                </span>
                            </label><br /><br />
                            <button type="submit" onClick={show_otp_pop_up} onTouchStart={(e) => handleChange(e)} onMouseEnter={(e) => handleChange(e)} className="sign_mobile">Sign</button>
                        </form>
                    </div>



                </div>
            </div>

            <div className={verify_mobile_1 ? "hide_verify_mobile" : "verify_mobile"}>
                <div className="verify_mob_inner">

                    <div className="verify_mob_head">
                        <h2>I want to <span id="verify_mob_clr">Verify</span></h2>
                        <h4>No need to register, just sign,<br /> download and share.</h4>
                    </div>

                    <div className="verify_mob_upload">
                        <h2>Upload your jpeg or pdf.</h2>
                        <button className="want_verify" onClick={toggle_verify_div_mob}>Verify</button>
                    </div>
                </div>
            </div>

            <div className={verifying_mob ? "verfiy_mob_1" : "hide_verfiy_mob_1"}>
                <div className="verify_mob_inner_1">
                    <div className="verify_1_head">
                        <h2>I am <span id="verify_mob_clr">Verfying.</span></h2>
                        <h4>No need to register, just upload and authenticate.</h4>
                    </div>

                    <div className="verifying_1_form">
                        <form>
                            <label > Txn Id: <input name="trxnId" id="get_id_mob" value={txn_state.trxnId} onChange={store_txn_id} type="text" /></label><br /><br /><br />
                            <button className="choose_div_btn_1_mob"   >
                                <input type="file" name="verify_data" value={verify_data} onChange={save_verifying_file} id="upload-photo2" />
                                <h2 id="overlap_text_1_mob">Choose your File</h2>
                            </button>

                            <button onClick={if_verified_mob} className="submit_id_mob">Submit</button>
                        </form>
                    </div>

                </div>
            </div>

            <div className={verified_yes_mob ? "verifying_yes_mob" : "hide_verifying_yes_mob"}>
                <div className="verifying_yes_mob_in">

                    <div className="yes_details">
                        <h2>The document was signed with the following details-:</h2>
                    </div>
                    <div className="yes_details_more">
                        <div className="newYes">
                            <div className="dataSpan">
                                <span>Name :</span>
                            </div>
                            <div className="dataField">
                                <span>{verified_name}</span>
                            </div>
                        </div>
                        <div className="newYes">
                            <div className="dataSpan">
                                <span>Email :</span>
                            </div>
                            <div className="dataField">
                                <span>{verified_email}</span>
                            </div>
                        </div>
                        <div className="newYes">
                            <div className="dataSpan">
                                <span>Place :</span>
                            </div>
                            <div className="dataField">
                                <span>{verified_place}</span>
                            </div>
                        </div>
                        <div className="newYes">
                            <div className="dataSpan">
                                <span>Time :</span>
                            </div>
                            <div className="dataField">
                                <span>{signTime}</span>
                            </div>
                        </div>
                        <div className="newYes alignCentre">
                            <p id="special_green_mob">The document has been successfully authenticated on blockchain...</p>
                        </div>
                        <div className="newYes alignCentre">
                            <button className="mob_another_btn" onClick={mob_move_back}>Verify Another</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={verified_no_mob ? "verifying_no_mob" : "hide_verifying_no_mob"}>
                <div className="verifying_no_inn_mob">

                    <div className="question_mob">
                        <h2> The document’s authenticity could not be verified on blockchain.</h2>
                        <ImCross id="cross_mob" />
                    </div>
                    <button className="wrong_mob_btn" onClick={no_moving_back}>Verify Another</button>
                </div>
            </div>

            {/* ******************************************************** */}

            <div className="ClienstElem" ref={partners_ref}>
                <Clients />
            </div>

            <div className="homeFooter">
                <Footer />
            </div>
            {uploadLoader === true ?
                <div className="Load">
                    <div className="loader_overlay"></div>

                    <div className="loader_content displayFlexMe">
                        <span>Preparing your File. Please wait</span>
                        <ThreeDots color="#ff7200" size={40} />
                    </div>

                </div>
                : ''
            }

            {
                otp_screen && (
                    <div className={otp_screen ? "email_pop_up" : "hide_email_pop"}>
                        <div className="overlay"></div>
                        <div className="email_inner">
                            <button className="sigCloseBtn" onClick={close_otp_pop_up}><FiX id="sigClose" /></button>
                            <div className="otp_image">
                                <GoMailRead id="mail" />
                                <h3>Please enter the OTP received on your email ID</h3>
                            </div>

                            <div className="otp_bottom">
                                <label></label><input type={passType} id="otp_input" name="otp" value={otp} inputMode='numeric' pattern="[0-9]*" onChange={(e) => otp_fun(e)} />
                                {passType === 'password' ?
                                    <AiFillEyeInvisible onClick={setPassTypeFun} className="visibleEye" />
                                    :
                                    <AiFillEye onClick={setPassTypeFun} className="visibleEye" />
                                }
                                <button className="otp_btn" onClick={getUserSign}>VERIFY</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                loader && (
                    <div className="Load">
                        <div className="loader_overlay"></div>

                        <div className="loader_content">

                            <ClipLoader color="#ff7200" size={40} />
                        </div>

                    </div>
                )
            }
            {verifyStatus === true ?
                <div className="Load">
                    <div className="loader_overlay"></div>
                    <div className="loader_content displayFlexMe">
                        <span>Please wait while we verify the uploaded document on the blockchain ledger </span>
                        <ThreeDots color="#ff7200" size={40} />
                    </div>

                </div>
                : ''
            }


        </>

    );
};

export default Home;