import { React, useState } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import "../css/footer.css"
import companyLogo from "../images/logo1.png";
import Swal from 'sweetalert2'
import { NavLink } from 'react-router-dom';
import { Link } from "react-scroll";
import ScrollToTop from "react-scroll-to-top";
import { FaRegPaperPlane } from "react-icons/fa";
import android from "../images/android.png";
import ios from "../images/ios.png";
import twitter from "../images/twitter.png";
import linkedin from "../images/linkedin.png";
import fb from "../images/fb.png";
import location from "../images/location_1.png";
import mail from '../images/mail.png';
import call from "../images/call_1.png";
import { useHistory } from "react-router";




const Footer = () => {
    let history = useHistory()
    const [pop_up, set_pop_up] = useState(false)
    const [emailState, setEmailState]=useState({emailId:''})

    const _Change = (e) => {
        setEmailState({...emailState , [e.target.name] : e.target.value})
    }

    const show_pop_up = () => {
        history.push({
            state: emailState.emailId,
            pathname :'/home/form'
        })
    }

    if (pop_up) {
        document.body.classList.add('active_pop_up')
    } else {
        document.body.classList.remove('active_pop_up')
    }
    const focus_email = () => {

        document.getElementById("text_field1").focus()
        Swal.fire({
            position: 'bottom',
            icon: 'info',
            title: 'Please fill in the Email.',
            showConfirmButton: false,
            timer: 1500
        })
    
    }

    return (
        <div className="footer_main">
            <ScrollToTop id="scrolling_to_top" smooth color="black" />
            <div className="footer_part1">
                <div className="footer_heading1">
                    <a onClick={focus_email}>
                        <h1>Ready to Get Started?</h1><br />
                        <p>Share your contact details and our team will contact you shortly.</p>
                    </a>
                </div>

                <div className="email_1" >
                    <form>

                        <input id="text_field1" name="emailId" value={emailState.emailId} onChange={(e)=>_Change(e)} type="email" autoComplete="off"  placeholder="Enter Email" required />
                        <button onClick={() => show_pop_up()}  ><ArrowForwardIcon id="arrow2" /></button>

                    </form>
                </div>

            </div>




            <div className="footer_new_part">
                <div className="footer_logo">
                    <a href="/Home"> <img id="logo_footer" src={companyLogo} alt="logo" /></a>
                </div>
                <div className="data_footer1">
                    <div className="data_footer_1">
                        <div className="location_all">
                            <a href="https://www.google.com/maps/dir//lynkit/@28.5254549,77.2054582,12z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x390ce1656fbb7b65:0x7f0e4b2c21935a5c!2m2!1d77.2754985!2d28.5254729" target="_blank"><img id="locationn" src={location} alt="none" /></a>
                            <img id="locationn" src={call} alt="none" />
                            <img id="locationn" src={mail} alt="none" />
                        </div>
                    </div>

                </div>

                <div className="addressdiv">
                    <a id="addresss" href="https://www.google.com/maps/dir//lynkit/@28.5254549,77.2054582,12z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x390ce1656fbb7b65:0x7f0e4b2c21935a5c!2m2!1d77.2754985!2d28.5254729" target="_blank"> 246, Block A, Okhla Industrial Area Phase I, New Delhi-110020</a><br />
                    <a id="addresss_1" href="tel:+91 11 4082 4028">+91 11 4082 4028</a>
                    <a id="addresss_2" href="mailto:contact@lynkit.in">contact@lynkit.in</a>
                </div>
                <div className="data_footer3">
                    <p>Our Products</p>
                    <a className="data_footer3_1" href="https://www.lynktrac.io/" target="_blank"> Lynktrac®</a>
                    <a className="data_footer3_2" href="https://lynkit.io/" target="_blank"> Lynkit.io™</a>
                    <a className="data_footer3_3" href="https://www.lynkgrid.com/" target="_blank"> Lynkgrid®</a>
                    <a className="data_footer3_3" href="https://www.lynkgrid.com/" target="_blank"> Lynkgrid®- Warehouse</a>

                    <a className="data_footer3_4" target="_blank"> LynkID©</a>
                    <a className="data_footer3_4" href="https://www.pikmybox.com/" target="_blank"> PikMyBox™</a>
                </div>
                <div className="data_footer4">
                    <p>Company</p>
                    <Link
                        to="main_company"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        <NavLink className="data_footer4_one" to="/about-us"  >About Us</NavLink><br />
                    </Link>
                    <Link
                        to="customers_"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        <NavLink className="data_footer4_one" to="/home/customers" >Our Customers </NavLink><br />
                    </Link>
                    <Link
                        to="Advisory_main"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        <NavLink className="data_footer4_two" to="/advisory" >Advisory Board </NavLink><br />
                    </Link>
                    <NavLink className="data_footer4_three" to="/home/form" >Contact Us </NavLink><br />
                </div>

                <div className="data_footer5">
                    <p>Connect With Us</p>
                    <div className="social">
                        <a target="_blank" href="https://www.facebook.com/LynkitIndia" > <img src={fb} alt="none" /></a>
                        <a target="_blank" href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQFDdEZYJhmMPQAAAXo4RtjIpsDBX8MVvY402c7ESFKSQBOXcx8nhR6JUAfwR36JcT--FjUC1wFllcg9cG-Bp8ilT8W_IUGLl5IE4fZf4NJmSrepair4QAM4n-APKKhTtBcvLIA=&originalReferer=https://www.lynkit.in/&sessionRedirect=https%3A%2F%2Fin.linkedin.com%2Fcompany%2Flynkit-india"> <img src={linkedin} alt="none" /></a>
                        <a target="_blank" href="https://twitter.com/Lynkitindia"> <img src={twitter} alt="none" /></a>
                    </div>
                    <a target="_blank" href="https://play.google.com/store/apps/developer?id=Lynkit.&hl=en"><img id="android" src={android} alt="none" /></a>
                    <a target="_blank" href="https://apps.apple.com/us/developer/lynkit/id1455816051"><img id="android" src={ios} alt="none" /></a>
                </div>
            </div>
            <div className="last_foot">
                <p>Copyright ©  2019 Lynkit. All Rights Reserved</p>
            </div>
        </div>
    );
};
export default Footer;