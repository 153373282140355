import React, { useState } from 'react';
import "../css/advisory.css"
import Footer from "./Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import pritamSir from "../images/pritam_image.png";
import drMaheshSir from "../images/drmahesh_chandra.jpg";
import sameerSir from "../images/sameer.png";


import SwiperCore, {
    EffectCoverflow,
    Pagination,
    Navigation
} from "swiper/core";

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);


const advisor_array = [
    {
        image: pritamSir,
        heading: "Dr. Pritam Banerjee",
        text: "Dr. Pritam Banerjee is an international  trade and logistics specialist, having spent a large part of his career working on driving policy and regulatory reform related to logistics and connectivity, trade facilitation and e-commerce. He is currently engaged with the Asian Development Bank as a Logistics Sector Specialist – Consultant. Earlier, Dr. Banerjee was Senior Director, South Asia with the DHL Group,as well as with World Bank and Confederation of India Industry (CII). He is currently a member of the National Trade Facilitation Steering Committee and the FICCI Logistics Taskforce."
    },
    {
        image: drMaheshSir,
        heading: "Late Dr. Mahesh Chandra",
        text: "Late Dr. Mahesh Chandra was an Industrial Engineering graduate from IIT Roorkee and a Ph.D in Computer Aided Design. In 1983 he joined National informatics Centre where he was assigned the lead role in Computer Aided Design and graphics group. Later he played the key role in establishing Utility Mapping System for six metros in the country. During this period he worked towards the use of GPS in geodetic surveys and other applications. In 2001 he was given additional responsibilities of leading the team of Vahan and Sarathi applications on Pan India basis as National Director Transport Offices Computerisation - a National data base for vehicles and Driver Licenses. Dr Chandra also lead the computerization teams in Aviation, Shipping and the Parliament of India. Dr Chandra has published over 50 research papers/monographs in International journals. He is also a receipient of many awards including Skotch Challenger award for four years, Web Ratna platinum award CSI Nihilent Special merit award and Lifetime Achievement award and citation from Computer Society of India."
    },


]

const Advisory = () => {

    return(
    <>
        <div>
            <div className="Advisory_main">

                <div className="Advisory_heading">
                    <h1>Advisory Board</h1>
                </div>

                <div className="Advisory_submain">
                    <Swiper
                        navigation={true}
                        slidesPerView={window.innerWidth < 330 ? 3 : "auto"}
                        centeredSlides={true}
                        // onSlideChange={(swiper) => progress_bar_fun(swiper)}
                        observer={true}
                        observeParents={true}
                        loop={false}
                        watchOverflow={true}
                        pagination={true}
                        effect={'coverflow'}
                        allowTouchMove={true}
                        initialSlide={0}

                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 0,
                            modifier: 1,
                            spaceBetween: 75,
                            slideShadows: true,

                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                    >


                        {advisor_array.map((value) => {
                            return (
                                <SwiperSlide>
                                    <div className="cover">
                                        <div className="inner_div_1">
                                            <img src={value.image} alt="none" />
                                        </div>

                                        <div className="inner_div_2">
                                            <h3>{value.heading}</h3>
                                            <p>{value.text}</p>
                                        </div>
                                    </div>
                                    <div class="swiper-pager">
                                        <div className="swiper-button-next"></div>
                                        <div className="swiper-button-prev"></div>
                                    </div>


                                </SwiperSlide>)
                        })}

                    </Swiper>
                </div>
                <div className="Advisory_submain_2">
                    <div className="cover_1">
                        <div className="inner_div_1_1">
                            <img src={sameerSir} alt="" />
                        </div>

                        <div className="inner_div_2_1">
                            <h3>Sameer Wagle</h3>
                            <p>Sameer Wagle has over 24 years of venture capital and operating experience in India, South East Asia and North Asia. He is currently Managing Director at the Asian Healthcare Fund (AHF). Before joining AHF, Mr. Wagle career spans key positions at Nomura Securities as Executive Director, Partner at IL&FS Investment Managers, Vertex Management (a part of the Singapore Technologies / Temasek group) and GVFL Limited, one of the first Indian Venture Capital firms.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div className='advisoryFooter'>
            <Footer />
        </div>
    </>

    )
}
export default Advisory;