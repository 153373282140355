import React from 'react';
import "../css/about.css"
import aboutUs from "../images/aboutUs.jpg";
import Footer from "./Footer";

const AboutUs = () => {

    return (
        <>
            <div className="main_company" >
                <div className="sub_main_company">
                    <h1>About Us</h1>
                    <p>We at Lynkit believe in orchestrating efficiencies for our clients in every aspect of their supply chain with technology as the cornerstone for their businesses. Our mission is to help our clients excel at every possible touch point of the supply chain and Our motto is "orchestrating efficiency", which is what we set out to do in each of our projects by "lynking" every stakeholder, process and action into a seamless and integrated process flow. <br /><br /><br className="break" />
                        Lynkit's unique application suite covers the entire spectrum of the supply chain uses in the industry. All our products and services have been developed after extensive research and scalability as the focus.<span /><br className="break" /><br />With sheer devotion to technology, the founders have handpicked the technology stack for our products, which complements the domains we operate in. Our two main divisons are working on applications based on Blockchain and IoT domains and we have fully stacked B2B supply chain management and optmisation solutions spanning across blockchain based transportation management systems, IoT based trace and track solutions, process and document automation and e-governance solutions, which have been deployed in a diverse set of industries such as logistics & transportation, manufacturing and e-commerce.
                    </p>
                </div>

                <div className="sub_main_2">
                    <img id="Family" src={aboutUs} alt="none" />
                </div>
                <div className='emptyDiv'>

                </div>
            </div>
            <div className="about_foooter">
                <Footer />
            </div>
        </>
    );
};

export default AboutUs;